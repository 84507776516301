@import "variables";

#categories_block_top {
  clear: both;
  position: relative;
  top: 30px;
}

#left_column #categories_block_left .title_block{
	margin-bottom: 0;
}

#categories_block_left ul{
	padding: 0;
	list-style-type: none;
}
#categories_block_left ul:first-child{
	border-top:1px solid #d6d4d4; 
}
  #categories_block_top .sf-menu {
    position: relative; }
    #categories_block_top .sf-menu > li {
      position: static; }
      #categories_block_top .sf-menu > li > ul {
        width: 100%;
        top: 60px;
        margin: 0 20px; }
        #categories_block_top .sf-menu > li > ul > li {
          display: block;
          float: left;
          width: 20%;
          -webkit-box-sizing: border-box;
          -moz-box-sizing: border-box;
          box-sizing: border-box;
          padding: 0 10px; }
        #categories_block_top .sf-menu > li > ul > .category_thumb {
          display: block;
          clear: both;
          overflow: hidden;
          width: 100%; }
          #categories_block_top .sf-menu > li > ul > .category_thumb img {
            display: inline-block;
            width: 33%; }
      #categories_block_top .sf-menu > li h4 a {
        font-size: 1.1em; }
        #categories_block_top .sf-menu > li h4 a:before {
          display: none; }
      #categories_block_top .sf-menu > li .main-level-submenus {
        position: relative;
        display: block !important;
        visibility: visible !important;
        top: 0;
        background: none;
        box-shadow: none;
        padding: 0;
        left: 0; }
    #categories_block_top .sf-menu .category_thumb {
      display: none; }

/*#categories_block_left .block_content > ul {
  border-top: 1px solid #d6d4d4; }*/
#categories_block_left li {
  position: relative; }
#categories_block_left li a {
    color: lighten(theme-color(black),30%);
    display: block;
    font-size: 14px;
    padding: 5px 0;
}
#categories_block_left li a:hover{
	opacity: 0.8;
}
#categories_block_left li:not(:last-child) a{
	border-bottom: 1px solid #d6d4d4;
}
  #categories_block_left li span.grower {
    display: block;
    position: absolute;
    right: 0;
    top: 0;
    cursor: pointer;
    font-family: "FontAwesome";
    font-size: 14px; }
    #categories_block_left li span.grower.OPEN:before, #categories_block_left li span.grower.CLOSE:before {
      content: "\f068";
      display: block;
      vertical-align: middle;
      width: 20px;
      height: 20px;
      color: #333;
      line-height: 30px;
      text-align: center; }
    #categories_block_left li span.grower.CLOSE:before {
      content: "\f067";
      color: silver; }
  #categories_block_left li span.grower:hover + a,
  #categories_block_left li a:hover,
  #categories_block_left li a.selected {
    background: theme-color(white); }
  #categories_block_left li li a {
    font-weight: normal;
    color: #777777; }
    #categories_block_left li li a:before {
      content: "\f105";
      font-family: "FontAwesome";
      line-height: 29px;
      padding-right: 8px; }
